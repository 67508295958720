html{
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family:"Roboto","Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rbc-calendar {
  max-width: 100%;
  height: 500px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.500em;
}

/* Panel per pratica */

nav > .nav.nav-tabs{

  border: none;
    color:#fff;
    background:#272e38;
    border-radius:0;

}
nav > div a.nav-item.nav-link,
nav > div a.nav-item.nav-link.active
{
  border: none;
    padding: 9px 12px;
    color:#fff;
    background:#272e38;
    border-radius:0;
}

nav > div a.nav-item.nav-link.active:after
 {
  content: "";
  display: none;
  position: relative;
  bottom: -40px;
  left: -40%;
  border: 5px solid transparent;
  border-top-color: #e74c3c ;
}
.tab-content{
  background: #fdfdfd;
    line-height: 25px;
    border: 1px solid #ddd;
    border-bottom:5px solid #e74c3c;
    padding:15px 12px;
}

nav > div a.nav-item.nav-link:hover,
nav > div a.nav-item.nav-link:focus
{
  border: none;
    background: #e74c3c;
    color:#fff;
    border-radius:0;
    transition:background 0.20s linear;
}

/* Sezione relativa al login */
#login .container #login-row #login-column #login-box {

  max-width: 600px;
  height: 320px;
  border: 1px solid #9C9C9C;
  background-color: #EAEAEA;
}
#login .container #login-row #login-column #login-box #login-form {
  padding: 20px;
}
#login .container #login-row #login-column #login-box #login-form #register-link {
  margin-top: -85px;
}

.tab-stato-active{
  background-color: rgba(128, 126, 126, 0.322);
  color:#ffffff !important;
}
.tab-stato{
  cursor:pointer;
}

/* Rimozione border roudius */
code,
kbd,
pre,
.img-rounded,
.img-thumbnail,
.img-circle,
.form-control,
.btn,
.btn-link,
.dropdown-menu,
.list-group-item,
.input-group-addon,
.input-group-btn,
.nav-tabs a,
.nav-pills a,
.navbar,
.navbar-toggle,
.icon-bar,
.breadcrumb,
.pagination,
.pager *,
.label,
/* .badge, */
.jumbotron,
.thumbnail,
.alert,
.progress,
.panel,
.well,
.modal-content,
.tooltip-inner,
.popover,
.popover-title,
.carousel-indicators li {
    border-radius:0 !important;
}
.menu-item>a{
  font-size: 16px;
  color:white !important
}
.menu-item{
  margin-top: 5px;
  width: 100%;
  min-width: 170px;
}
#root{
  height: calc(100vh);
}
.break-text,.react-csv-input{
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
.navbar{
  background-color:#373a47
}
.drag-wrapper{
  z-index: 150;
  margin: 30px;
  border:1px dotted black;
  padding:0 1px;

  position: absolute;
  cursor: move;
}
.react-pdf__Document{
  z-index: 9;
  border:1px solid #606161;
  margin:0 auto;
}

.draggable-absolute{
  position: absolute;
  top:0;
  left:0;
}

#box-draggable-value{
  padding:3px;
  background-color: #DCDCDC;
  /* opacity: 0.9; */
  position: absolute;
  top:0;
  left:0;
  width:200px;
  z-index: 15;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
}
.far,.fas{
  margin-right: 5px;
}
.stiky-header{
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
  z-index: 100;
}

.bar-box{
  z-index:11;
  width:919px;
  margin:0 auto;
  background-color: #6a6a6d;
}
.bar-box-label{
 margin-right: 10px;
 color:white;
 margin-top:10px;
}

.box-draggable-background{
  position:absolute; 
  z-index:15; 
  background-color:black;
  top:0;
  bottom:0;
  left:0;
  right:0;
  opacity:0.2
}
@keyframes border-pulsate {
  0%   { border-color: rgb(235, 13, 161); }
  50% { border-color: rgba(0, 255, 255, 1); }
  100%   { border-color: rgba(235, 13, 161); }
}

.drag-wrapper-pulsing{
  animation: border-pulsate 2s infinite;;
}

.div-schema-color {
  width: 15px;
  height: 15px;
  margin:"0 auto";
  margin-top: 15px;
  border-radius: 50%;
  border:1px solid #606161;
}

.div-schema-color-selected {
  border:1px solid #606161;
}

.rectimpostazioni{
  font-size: 25px;
  border: 1px solid lightgray;
  color:rgb(59, 59, 59);
  padding: 15px;
  margin:5px 15px;
}
.rectimpostazioni:hover{
  background-color: #3f51b5;
  color:white;
}
.MuiTableHead-root{
  color:white !important;
  background-color: #3f51b5;
}
.MuiTableCell-head{
  color:white !important;
}
.MuiBox-root{
  padding-left:0 !important;
  padding-right:0 !important;
}
.bar-divider{
  z-index: 1000;
  background-color: #e7e7e7;
  padding:0 15px;
  margin-bottom: 10px;
}
.liste-trovato{
  background-color: rgb(247, 90, 90)
}

.sortdocumenti,.sortdocumenti-disabled{
  padding:10px;
  cursor:all-scroll
}
.sortdocumenti-disabled{
  color:rgb(192, 191, 191)
}
